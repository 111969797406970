
import store from "../store";

const numeral = require("numeral");
const isUSD = store.getters.getSession.currency === 'usd'
export default {
    formatInt(value)
    {
        if (!value) {
            return value;
        }

        value = parseInt(value);
        value = value.toFixed(0);

        return numeral(value).format("0,0");
    },
    formatNumber(value, currency)
    {
        if (!value) {
            return value;
        }
        if (!currency) {
            currency = (isUSD) ? 'usd' : 'krw';
        }

        if (typeof value !== 'number') {
            if (currency === 'usd') {
                value = parseFloat(value);
            } else {
                value = parseInt(value);
            }
        }

        if (currency === 'usd') {
            value = numeral(value.toFixed(2)).format("0,0.00");
        } else {
            value = numeral(value.toFixed(0)).format("0,0");
        }

        return value;
    },
    Ymd(value)
    {
        if (!value)
            return null;
        return value.substring(0, 10);
    },
    YYMMDD(value)
    {
        if (!value)
            return null;
        return value.substring(2, 10);
    },
    YmdHi(value)
    {
        if (!value)
            return null;
        return value.substring(0, 16);
    },
    shortDate: (value) => {
        return value.substring(0, 16);
    }
}
