<template>
  <button ref="btn" type="button"
          style="min-height: 38px"
          class="text-13 lg:text-14 py-2 px-2 transition-ease is-activated focus:outline-none whitespace-no-wrap relative"
          :class="getClass" v-on:click="clickHandler">
    <div v-if="loading" class="loading-mask w-full h-full z-10 absolute top-0 left-0 transition-ease">
      <div class="flex items-center justify-center w-full h-full">
        <svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
        </svg>
      </div>
    </div>
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'EvButton',
  props: {
    type: {
      type: String,
      default: 'basic'
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      defaultClass: [
        'py-2',
        'px-2',
        'transition-ease',
        'is-activated',
        'focus:outline-none',
        'focus:gray-shadow',
      ],
      typeClass: {
        primary: [
          'bg-purple-600',
          'text-white',
          'focus:purple-shadow'
        ],
        basic: [
          'border',
          'border-purple-200',
          'bg-white',
          'text-purple-400',
          'focus:purple-shadow'
        ],
        secondary: [
          'bg-blue-500',
          'focus:bg-blue-400',
          'text-white',
          'focus:blue-shadow'
        ],
        warning: [
          'bg-orange-500',
          'focus:bg-orange-400',
          'text-white',
          'focus:orange-shadow'
        ],
        danger: [
          'bg-red-500',
          'focus:bg-red-400',
          'text-white',
          'focus:red-shadow'
        ],
        plain: [
          'bg-white-400',
          'hover:text-gray-700',
          'focus:bg-gray-400',
          'text-gray-600',
          'focus:gray-shadow'
        ]
      }
    }
  },
  methods: {
    clickHandler(e) {
      if (this.loading) {
        return;
      }
      if (this.$listeners.click) {
        return this.$listeners.click(e);
      }
    }
  },
  computed: {
    getClass() {
      return this.typeClass[this.type];
    }
  }
}
</script>
<style scoped lang="scss">
.circular {
  height: 36px;
  width: 36px;
  animation: loading-rotate 2s linear infinite;

  .path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: #FFFFFF;
    stroke-linecap: round;
  }
}

.loading-mask {
  background: rgba(10, 10, 10, 0.3);
}

.focus\:gray-shadow:focus {
  box-shadow: 0 0 0 3px #606060
}

.is-activated:active {
  transform: translateY(2px);
}
</style>
