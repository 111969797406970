<template>
  <section class="relative mx-auto">
    <!-- navbar -->
    <nav class="flex justify-between bg-indigo-900 text-white w-screen">
      <div class="px-2 py-3 flex w-full items-center justify-between container mx-auto text-white">
        <h1 class="text-3xl font-bold font-heading" href="#">
          <!-- <img class="h-9" src="logo.png" alt="logo"> -->
          비밀향원
        </h1>
        <!-- Header Icons -->
        <div class="flex items-center space-x-5 items-center">
          <router-link to="/">
            <icons name="order" class="text-white hover:text-indigo-600" ></icons>
          </router-link>
          <router-link :to="{name:'CartFormPage'}" >
            <icons name="cart" class="text-white hover:text-indigo-600"/>
          </router-link>
          <!-- Sign In / Register !!    -->
          <router-link to="/my-page" >
            <icons name="user" class="text-white hover:text-indigo-600"/>
          </router-link>

        </div>
      </div>

    </nav>

  </section>
</template>

<script>
import Icons from "@/icons/Icons";
export default {
  name: "EvHeader",
  components: {Icons}
}
</script>

<style scoped>

</style>
