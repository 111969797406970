<template>
  <div class="w-full min-h-screen bg-gray-300" v-if="isChecked">
    <ev-header></ev-header>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import {reactive} from "@vue/composition-api";
import EvHeader from "@/domains/EvHeader";

export default {
  name: "MainContainer",
  components: {EvHeader},
  data(){
    return {
      isChecked : false
    }
  },
  async created() {
    await this.$store.dispatch('loginCheck');
    this.isChecked = true;
  }
}
</script>

<style scoped>

</style>
