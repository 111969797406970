<template>
  <div class="relative border border-main-200 hover:border-gray-800 focus:border-gray-800" tabindex="0">
    <ul ref="dropButton" style="min-height: 40px;"
        class="bg-white flex flex-row flex-wrap h-full w-full select-none cursor-pointer">
      <li v-if="!hasValue"
          class="select-none cursor-pointer text-gray-500 m-1 ml-2 h-full py-1 px-2 text-sm">
        {{ placeholder }}
      </li>
      <li v-else v-for="item in getValue"
          @click="()=>{ if(nullable){ unselectItem(item); } }"
          :class="(hasColorOption(item)) ? [ ...getColor(item) ] : []"
          class="text-sm select-none m-1 rounded ml-1 first:ml-2 border px-2 whitespace-nowrap ">
        <slot :name="resultSlotName" :item="item">{{ getTag(item) }}</slot>
      </li>
    </ul>
    <div ref="dropMenu"
         :style="dropMenuStyle"
         class="bg-white border-t rounded shadow-md absolute top-0 right-0 min-w-full overflow-auto z-30 hidden max-h-50vh "
    >
      <ul class="list-reset m-1">
        <template v-for="item in options">
          <li
              @click="toggleItem(item)"
              :class="(hasColorOption(item)) ? [ ...getColor(item) ] : []"
              class="border-t border-gray-200 hover:bg-gray-100 first:border-t-0 flex flex-row flex-wrap active:bg-gray-200 "
          >
            <span
                class="select-none cursor-pointer text-gray-800 m-1 ml-2 h-full py-1 px-1 whitespace-nowrap"
                :class=" isSelected(item) ? selectedItemCSS : [] "
            >
                <slot name="option" :item="item">{{ getTag(item) }}</slot>
            </span>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "EvTagSelect",
  props: {
    placeholder: {
      type: String,
      default() {
        return '선택해 주세요.'
      }
    },
    valueKey: {
      default: 'value'
    },
    labelKey: {
      default: 'label'
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    single: {
      type: Boolean,
      default: false
    },
    value: {
      default() {
        return []
      }
    },
    max: null,
    nullable: {type: Boolean, default: false, required: false}
  },
  data() {
    return {
      dropMenuStyle: {}
    }
  },
  computed: {
    itemCSS() {
      return [];
    },
    selectedItemCSS() {
      return ['rounded', 'bg-gray-200', 'text-bold']
    },
    getValue() {
      if (this.single) {
        return this.options.filter(item => {
          if (typeof item === 'object') {
            return item[this.valueKey] === this.value;
          } else {
            return item === this.value;
          }
        })
      }

      if (!Array.isArray(this.value)) {
        return [];
      }

      return this.value;
    },
    hasValue() {

      return this.getValue.length !== 0;
    },
    resultSlotName() {

      if (this.$scopedSlots.option && !this.$scopedSlots.result) {
        return 'option';
      }
      return 'result';
    }
  },
  methods: {
    getDropMenuStyle() {
      let dropButton = this.$refs['dropButton'];
      if (dropButton) {
        setTimeout(() => {
          this.dropMenuStyle = {'margin-top': dropButton.clientHeight + "px"};
        }, 10);
      }
    },
    itemFormat(item) {
      let out = {};
      out[this.valueKey] = item[this.valueKey];
      out[this.labelKey] = item[this.labelKey];

      return out;
    },
    isSelected(item) {

      return this.getValue.filter((i) => {

        if (typeof i === 'object') {
          return i[this.valueKey] && item[this.valueKey] && i[this.valueKey] === item[this.valueKey];
        }
        return i === item;
      }).length > 0;
    },
    selectItem(item) {
      if (this.max && this.max === this.getValue.length) {
        this.$message({type: 'warning', content: '최대 ' + this.max + '개만 선택 가능 합니다.'});
        return;
      }
      if (this.max && this.max === this.getValue.length + 1) {
        this.hiddenDropDownMenu();
      }
      if (this.single) {
        this.hiddenDropDownMenu();
        this.$emit('input', (item[this.valueKey]) ? item[this.valueKey] : item);
        return;
      }
      this.getDropMenuStyle();
      this.$emit('input', this.getValue.concat([item]));
    },
    toggleItem(item) {
      if (this.isSelected(item) && this.nullable) {
        this.unselectItem(item);

      } else {
        this.selectItem(item);
      }
    },
    unselectItem(item) {
      let items = this.getValue.filter((i) => {
        return i !== item;
      });
      this.getDropMenuStyle();
      if (this.single && items.length === 0) {
        this.$emit('input', '');
        return;
      }
      this.$emit('input', items);
    },
    checkParent(t, elm) {
      while (t.parentNode) {
        if (t === elm) {
          return true;
        }
        t = t.parentNode;
      }
      return false;
    },
    hiddenDropDownMenu() {
      if (!this.$refs['dropMenu'].classList.contains('hidden')) {
        this.$refs['dropMenu'].classList.add('hidden')
      }
    },
    showDropDownMenu() {

      if (this.$refs['dropMenu'].classList.contains('hidden')) {
        this.$refs['dropMenu'].classList.remove('hidden');
        this.getDropMenuStyle();

      }
    },
    documentClickEventHandler(e) {
      var target = (e && e.target);
      let dropButton = this.$refs['dropButton'];
      let dropMenu = this.$refs['dropMenu'];
      //User Menu
      if (!this.checkParent(target, dropMenu)) {
        // click NOT on the menu
        if (this.checkParent(target, dropButton)) {
          // click on the link
          if (dropMenu.classList.contains("hidden")) {
            dropMenu.classList.remove("hidden");
          } else {
            dropMenu.classList.add("hidden");
          }
        } else {
          // click both outside link and outside menu, hide menu
          dropMenu.classList.add("hidden");
        }
      }

    },
    getTag(item) {
      if (item[this.labelKey])
        return item[this.labelKey];
      else
        return item;
    },
    getColor(item) {
      let color;
      if (!this.hasColorOption(item)) {
        color = 'gray';
      } else {
        color = item.color;
      }

      return [`hover:bg-${color}-200`, `active:bg-${color}-300`, `bg-${color}-100`, `text-${color}-800`, `border-${color}-400`];
    },
    hasColorOption(item) {
      return item && item.color;
    }
  },
  mounted() {
    this.getDropMenuStyle();
    document.addEventListener('click', this.documentClickEventHandler);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClickEventHandler);
  }
}
</script>

<style scoped>
.max-h-50vh{
  max-height: 50vh;
}
  .whitespace-nowrap{
    white-space: nowrap;
  }
</style>
