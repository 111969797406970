import "./polyfill.js"

import '~@sass/element_variables.scss';
import "~@sass/tailwind.scss"
import "~@sass/app.scss"

import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';

import EvansUI from './evans'
import EvMessage from './evans/plugins/EvToast';
import SequencePlugIn from "./evans/plugins/Sequence";
import DirectiveRoute from "@/evans/plugins/DirectiveRoute";

import Layout from "./Layout";
import MainContainer from "@/MainContainer";
import store from './store'

Vue.component(MainContainer.name, MainContainer);

Vue.use(VueCompositionApi);
Vue.use(EvansUI);
Vue.use(EvMessage);
Vue.use(SequencePlugIn);
Vue.use(DirectiveRoute);

import {Button, Select, Option, Popover, Pagination, Checkbox, Input, InputNumber, MessageBox} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/ko'
import locale from 'element-ui/lib/locale'
locale.use(lang);

Vue.component(Button.name, Button)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Popover.name, Popover)
Vue.component(Pagination.name, Pagination)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Input.name, Input)
Vue.component(InputNumber.name, InputNumber)

Vue.prototype.$confirm = MessageBox.confirm;

import axios from "@/plugins/axios";
Vue.prototype.$axios = axios;
import filters from "@/extensions/GlobalFilters";

Vue.mixin({
    filters : filters,
    computed:{
        isMobile(){
            return window.innerWidth < 500;
        },
        isUSD(){
            return false;
        },
        isKorean(){
            return true;
        },
        isisAuthenticated(){
            return !!store.getters.getUser.user_id
        }
    },
    methods:{
        __(k,e){
            return k;
        }
    }
})
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import router from "./router";

window.vm = new Vue({
    router,
    store,
    render: h => h(Layout)
}).$mount('#app')


Sentry.init({
    Vue,
    dsn: 'https://67ff4353dcad46658819a40de6bcfa62@sentry.io/1506692',
    ignoreErrors: [
        "Non-Error exception captured",
        "Non-Error promise rejection captured",
        /Request failed with status code 4.*!/,
        "ResizeObserver loop limit exceeded",
        /Loading CSS chunk.*!/,
        /Failed to register a ServiceWorker.*!/,
        /Chunk.*!/
    ],
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "ssgarden.co.kr", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
});


