import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name:'index',
        component: () => import(/* webpackChunkName: "routes/index" */'./pages/index.vue'),
    },
    {
        path: '/login',
        name:'Login',
        component: () => import(/* webpackChunkName: "routes/login" */'./pages/Login.vue'),
        meta: {
            guestOnly : true
        }
    },
    {
        path: '/register',
        name:'RegisterPage',
        component: () => import(/* webpackChunkName: "routes/register" */'./pages/RegisterPage.vue'),
        meta: {
            guestOnly : true
        }
    },
    {
        path: '/reset',
        name:'Reset',
        component: () => import(/* webpackChunkName: "routes/reset" */'./pages/Reset.vue'),
        meta: {
            guestOnly : true
        }
    },
    {
        path: '/cart',
        name:'CartFormPage',
        component: () => import(/* webpackChunkName: "routes/cart" */'./pages/CartFormPage.vue'),
    },
    {
        path: '/my-page',
        name:'MyPage',
        component: () => import(/* webpackChunkName: "routes/myPage" */'./pages/MyPage.vue'),
    },
    {
        path: '/my-page/order',
        name:'OrderPage',
        component: () => import(/* webpackChunkName: "routes/order" */'./pages/OrderPage.vue'),
    },
    {
        path: '/product',
        name:'ProductBriefPage',
        component: () => import(/* webpackChunkName: "routes/product" */'./pages/ProductBriefPage.vue'),
    }
];

const router = new VueRouter({
    mode: 'history',
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    const authenticated = store.getters.getUser.mb_id;
    if(to.meta.guestOnly){
        await next()
        return;
    }

    if(!authenticated){

        await next({name:'Login'});
        return;
    }

    await next();

});

export default router;
