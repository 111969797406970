<template>
  <component :is="getLayout" v-if="getLayout" :style="getFont"></component>
  <div v-else>
    <template>
      <keep-alive :max="5">
        <router-view></router-view>
      </keep-alive>
    </template>
  </div>
</template>

<script>

export default {
  name: "Layout",
  computed: {
    getFont() {
      return this.isKorean ? {'font-family': 'Noto sans KR'} : {'font-family': 'Noto sans'}
    },
    getLayout() {

      if (!this.$route || this.$route.name === null) {
        return false;
      }
      if (this.$route.meta.layout === 'blank') {
        return false
      }

      return 'main-container'

    }
  },
  created() {

  }
}
</script>
<style>

</style>
