<template>
    <div>
        <div class="flex flex-row w-full">
            <ev-datepicker :value="localValue.start" @input="updateStart" format="yyyy-MM-dd"/>
            <div class="text-2xl"> ~ </div>
            <ev-datepicker :value="localValue.end" @input="updateEnd" format="yyyy-MM-dd" />
        </div>
        <ev-radio-group class="mt-2" v-if="selectGroup" name="groups"
            :value="selectedGroup" @input="changeDate" :options="getSelectHelper"></ev-radio-group>
    </div>
</template>

<script>
    import EvDatepicker from "./EvDatepicker";
    import moment from "moment";
    export default {
        name: "EvDateRange",
        components: {EvDatepicker},
        props:{
            beforeDays : {
                default : 30
            },
            selectGroup : {
                type : Boolean, Array,
                default: null
            },
            value:null
        },
        watch:{
            value(obj, old){
                if(!obj){
                    return;
                }
                this.localValue = obj;
            }
        },
        methods: {
            updateStart(val) {
                this.localValue.start = val;
                this.$emit('input', this.localValue)
            },
            updateEnd(val) {
                this.localValue.end = val;
                this.$emit('input', this.localValue)
            },
            changeDate(val) {
                this.selectedGroup = val;
                let value = (new moment()).subtract(parseInt(val), 'days').format("YYYY-MM-DD");
                this.updateStart(value);
            }
        },
        data() {
            return {
                localValue : {
                    start: '',
                    end : ''
                },
                selectedGroup: null
            }
        },
        mounted() {
            if(this.value){
                this.localValue = this.value
            }
        },
        computed: {
            getSelectHelper() {
                if( Array.isArray( this.selectGroup) && this.selectGroup.length > 0 ){
                    return this.selectGroup;
                }
                return [
                    {label:'오늘', value:1},
                    {label:'일주일', value:7},
                    {label:'15일', value:15},
                    {label:'한달', value:30},
                    {label:'두달', value:60},
                    {label:'석달', value:90},
                    {label:'반년', value:180},
                    {label:'일년', value:365},
                ]
            }
        }
    }
</script>

<style scoped>

</style>
