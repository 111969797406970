import ErrorHandler from '../mixins/ErrorHandler'
import axios from 'axios';

const instance = axios.create()

instance.defaults.withCredentials = true;
instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
instance.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
instance.interceptors.response.use(
    (res)=>{
        if(res.data.message){
            window.vm.$message(res.data.message);
        }
        return res;
    },
    ErrorHandler
);

export default instance;
