import Vue from 'vue'

import EvAlert from "./components/EvAlert";
import EvButton from "./components/EvButton";
import EvCard from "./components/EvCard";
import EvCheckBox from "./components/EvCheckBox";
import EvCounterInput from "./components/EvCounterInput";
import EvDatepicker from "./components/EvDatepicker";
import EvDateRange from "./components/EvDateRange";
import EvDialog from "./components/EvDialog";
import EvDropdown from "./components/EvDropdown";
import EvDropdownOption from "./components/EvDropdownOption";
import EvForm from "./components/EvForm";
import EvFormField from "./components/EvFormField";
import EvGroupCheckBox from "./components/EvGroupCheckBox";
import EvInput from "./components/EvInput";
import EvMoneyInput from "./components/EvMoneyInput";
import EvPagination from "./components/EvPagination";
import EvRadioGroup from "./components/EvRadioGroup";
import EvRemoteSelect from "./components/EvRemoteSelect";
import EvRemoteSelectOptions from "./components/EvRemoteSelectOptions";
import EvSelect from "./components/EvSelect";
import EvSwitch from "./components/EvSwitch";
import EvTable from "./components/EvTable";
import EvTableColumn from "./components/EvTableColumn";
import EvTagSelect from "./components/EvTagSelect";
import EvUploadInput from "./components/EvUploadInput";
import EvQueryManager from "./components/EvQueryManager";
import EvTextarea from "./components/EvTextarea";
import EvPopup from "./components/EvPopup";
import EvProgressBar from "./components/EvProgressBar";
import EvTag from "./components/EvTag";

export default {
    get components(){
        return {
            EvAlert,
            EvButton,
            EvCard,
            EvCheckBox,
            EvCounterInput,
            EvDatepicker,
            EvDateRange,
            EvDialog,
            EvDropdown,
            EvDropdownOption,
            EvForm,
            EvFormField,
            EvGroupCheckBox,
            EvInput,
            EvMoneyInput,
            EvPagination,
            EvRadioGroup,
            EvRemoteSelect,
            EvRemoteSelectOptions,
            EvSelect,
            EvSwitch,
            EvTable,
            EvTableColumn,
            EvTagSelect,
            EvUploadInput,
            EvQueryManager,
            EvTextarea,
            EvPopup,
            EvProgressBar,
            EvTag
        }
    },
    install : function (Vue, options) {
        Vue.component(EvAlert.name, EvAlert);
        Vue.component(EvButton.name, EvButton);
        Vue.component(EvCard.name, EvCard);
        Vue.component(EvCheckBox.name, EvCheckBox);
        Vue.component(EvCounterInput.name, EvCounterInput);
        Vue.component(EvDatepicker.name, EvDatepicker);
        Vue.component(EvDateRange.name, EvDateRange);
        Vue.component(EvDialog.name, EvDialog);
        Vue.component(EvDropdown.name, EvDropdown);
        Vue.component(EvDropdownOption.name, EvDropdownOption);
        Vue.component(EvForm.name, EvForm);
        Vue.component(EvFormField.name, EvFormField);
        Vue.component(EvGroupCheckBox.name, EvGroupCheckBox);
        Vue.component(EvInput.name, EvInput);
        Vue.component(EvMoneyInput.name, EvMoneyInput);
        Vue.component(EvPagination.name, EvPagination);
        Vue.component(EvRadioGroup.name, EvRadioGroup);
        Vue.component(EvRemoteSelect.name, EvRemoteSelect);
        Vue.component(EvRemoteSelectOptions.name, EvRemoteSelectOptions);
        Vue.component(EvSelect.name, EvSelect);
        Vue.component(EvSwitch.name, EvSwitch);
        Vue.component(EvTable.name, EvTable);
        Vue.component(EvTableColumn.name, EvTableColumn);
        Vue.component(EvTagSelect.name, EvTagSelect);
        Vue.component(EvUploadInput.name, EvUploadInput);
        Vue.component(EvQueryManager.name, EvQueryManager);
        Vue.component(EvTextarea.name, EvTextarea);
        Vue.component(EvPopup.name, EvPopup);
        Vue.component(EvProgressBar.name, EvProgressBar);
        Vue.component(EvTag.name, EvTag);
    }
}
