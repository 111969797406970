import Vue from 'vue';

function RouteEvent(el, binding, vnode){
    this.el = el;
    this.binding = binding;
    this.vnode = vnode;

    this.routeEvent=()=>{


        this.vnode.context.$router.push(this.binding.value);
    }
}
let RouteEventInstance = null;
export default {
    /**
     *
     * @param {Vue} Vue
     * @param options
     */
    install(Vue, options) {
        this.instance = null;

        Vue.directive('route', {
            bind(el, binding, vnode) {

                el.classList.add('cursor-pointer');
                RouteEventInstance = new RouteEvent(el, binding, vnode);
                RouteEventInstance.el.addEventListener('click', RouteEventInstance.routeEvent);
            },
            unbind(el){
                el.classList.remove('cursor-pointer');
                el.removeEventListener('click', RouteEventInstance.routeEvent);
            }
        })

    }
}