<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <el-date-picker
            class="w-full ev-date-picker"
            ref="picker"
            :value="value"
            @input="inputHandler"
            :type="pickerType"
            :value-format="format"
    ></el-date-picker>
</template>

<script>

    export default {
        name: "EvDatepicker",
        props:{
            value: null,
            pickerType:{
              default: 'date'
            },
            format:{
                default:'yyyy-MM-dd'
            },
            timeable : {
                default : false
            }
        },
        data() {
            return {
                /**
                 * moment
                 */
                date:null,
                hour:'',
                min:'',
                calendar : {},
                isChanged: false,
                invalid: false,
                focused : false,
            }
        },
        computed: {
            getInputType() {
                return (this.$attrs['type']) ? this.$attrs['type'] : 'text';
            },
            hasErrorField() {
                return this.$parent.hasError;
            },
            picker(){
                return this.$refs['picker']
            },
            getFocusedDates() {
                return { dates: [ (this.value) ? new Date(this.value) : new Date() ]}
            }
        },
        methods: {
            inputHandler(e) {

                this.$emit('input', e);
            }
        }
    }
</script>

<style scoped>
    .ev-date-picker.w-full.el-input{
        width: 100% !important;
    }
</style>
