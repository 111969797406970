import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from './plugins/axios';
import {messenger} from "./evans/plugins/EvToast";
import router from "./router";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const storage = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        session: {
            currency: 'krw',
            lang: 'kr'
        },
        user: {
            name: null,
            user_id: null,
            isGuestOrder : true
        }
    },
    mutations: {
        unsetUser(state, payload){
            state.user = {
                name: null,
                user_id: null,
                isGuestOrder : true
            };
        },
        setUser(state, payload){
            state.user = Object.assign(state.user, payload);
            state.user.isGuestOrder = false;
        },
        setSession(state, {lang,currency}){
            state.session.currency = currency;
            state.session.lang = lang
        }
    },
    actions: {

        async loginCheck({ commit, state}){
            const res = await axios.get('/api/branch/shake');
            if(!res || !res.data){
                return;
            }
            commit('setSession', {
                lang : res.data.lang || 'kr',
                currency : res.data.currency || 'krw'
            });

            if( !res.data.authenticated ){
                commit('unsetUser');
                return;
            }

            if(res.data.user){
                await commit('setUser', res.data.user);
            }
        },
        async loginAsUser({commit, state}, payload){

            commit('unsetUser');
            const res = await axios.post("/api/branch/login", payload);
            commit('setUser', res.data.user);

        },
        async logoutAsUser({commit}){
            await axios.post("/api/branch/logout");
            commit('unsetUser');
            messenger.info("안전하게 로그아웃 되었습니다.");
        },
        async forgetPassword(context, payload){

            try {
                const res = await axios.post("/api/branch/forgot-password", {
                    mb_hp: payload.mb_hp
                });

                messenger.success("비밀번호 초기화 링크 문자를 발송하였습니다.");
            } catch (e) {
                messenger.error(e.response.data.message);
            }

        },
        async resetPassword(context, payload){
            try {
                await axios.post('/api/branch/reset-password', {
                    'token': payload.token,
                    'mb_hp': payload.mb_hp,
                    'password': payload.password,
                    'password_confirmation': payload.password_confirmation
                });
                messenger.success("비밀번호가 변경되었습니다.");
            } catch (e) {
                messenger.error(e.response.data.message);
            }
        }

    },
    getters: {
        getUser(state, getters){
            return state.user
        },
        getSession(state, getter) {
            return state.session
        }
    },
});
export default storage
